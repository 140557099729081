.blog-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blog-page .title {
  max-width: 800px;
  margin: 20px 0;
  font-size: 22px;
  text-align: center;
  font-family: "Bold";
  color: #0082C7;
}
.blog-page .content {
  width: 100%;
}
.blog-page .content strong {
  font-family: "Bold";
}
.blog-page .content ul li {
  list-style-type: circle;
}
.blog-page .content ol li, .blog-page .content ul li {
  list-style-position: inside;
}
.blog-page .content a {
  color: #0082C7;
}

