@import '../utils/variables.scss';
@import '../utils/mixins.scss';

.page-search {
  // border: 1px solid #cad3dc;
  border-radius: 5px;
  margin: 0 auto;
  max-width: 350px;
  min-height: 40px;
  padding: 1px 4px;
  min-width: 360px;
  background-color: #f2f4f6;

  display: flex;
  gap: 5px;
  position: relative;

  .elements-sorting {
    width: 30px;
    height: 30px;
    position: absolute;
    border-radius: 4px;
    left: -40px;
    top: 6px;
    background-color: #f2f4f6;
    transition: 0.3s all;

    @include flex;

    img {
      height: auto;
      transition: 0.3s all;
      max-width: 18px;
    }

    &.active {
      img {
        transform: rotate(180deg);
      }
    }
  }

  input {
    width: calc(100% - 35px);
    border: none;
    padding-left: 35px;
    background-image: url('../../../images/particals/search/search.svg');
    background-repeat: no-repeat;
    background-size: 19px;
    background-position: left;
    background-position-x: 6px;
    font-size: 15px;
    background-color: inherit;

    &::placeholder {
      color: #666666d6;
    }
  }

  .clear {
    align-self: center;
    min-width: 8px;
    
    span {
      display: none;
      cursor: pointer;
      user-select: none;

      &.active {
        display: block;
      }
    }
  }

  button {
    width: 70px;
    user-select: none;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 15px;
    font-family: 'Bold';
    transition: 0.4s;
    color: #444444b3;

    &:hover {
      color: $appColor;
    }
  }
}

@media (max-width: 490px) {
  .page-search {
    min-width: calc(75% - 8px);
    max-width: calc(75% - 8px);

    input {
      &::placeholder {
        font-size: 14px;
      }
    }

    button {
      display: none;
    }
  }
}