@import '../utils/variables.scss';
@import '../utils/mixins.scss';

.members-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;

  margin: 40px 0;
  padding-bottom: 11px;

  border-bottom: 1px solid #d2d2d7;

  span {
    color: #6e6e73;
    cursor: pointer;
    font-size: 17px;
    user-select: none;

    &.active {
      color: #000;
      position: relative;
      
      &::before {
        content: '';
        height: 1px;
        width: 100%;
        background-color: #000;
        
        position: absolute;
        bottom: -12px;
      }
    }
  }


}

.team {
  margin-top: 30px;

  display: flex;
  flex-direction: column;
  gap: 40px;

  &.preloader-mode {
    gap: 0;
  }
  
  .member {
    display: flex;
    gap: 15px;

    .profile-image {
      img {
        height: auto;
        max-width: 130px;
        border-radius: 10px;
        box-shadow: -3px -3px 7px #f2f2f2, 3px 3px 7px #c0c9d2;
      }
    }

    .profile-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .name {
        font-size: 27px;
        font-family: 'Bold';
      }

      .job-title {
        display: flex;
        flex-wrap: wrap;
        gap: 13px;

        span {
          border: 1px solid $appColor;
          color: $appColor;
          border-radius: 2px;
          font-size: 14px;
          padding: 2px 6px;
        }
      }

      .description {
        font-size: 14px;

        .toggle-info {
          background: none;
          border: none;
          cursor: pointer;
          color: $appColor;
          font-family: 'Bold';
          margin-left: 4px;
        }
      }
    }
  }
}


@media (max-width: 1000px) {
  .team {
    .member {
      .profile-info {
        .name {
          font-size: 22px;
        }

        .job-title {
          gap: 5px;
        }
      }
    }
  }
}


@media (max-width: 600px) {
  .team {
    .member {
      @include flex-column;

      .profile-info {
        @include flex-column;

        .name {
          text-align: center;
        }

        .job-title {
          justify-content: center;

          span {
            font-size: 12px;
            text-align: center;
          }
        }

        .description {
          text-align: center;
        }
      }
    }
  }
}