.blogs {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
}
.blogs.preloader-blogs .preview-block {
  margin: 0;
}
.blogs .blog {
  width: 400px;
  padding: 20px;
  box-shadow: -3px -3px 7px rgba(228, 227, 227, 0.5019607843), 3px 3px 7px rgba(168, 174, 180, 0.4705882353);
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
}
.blogs .blog:hover {
  background-color: rgba(204, 204, 204, 0.2);
}
.blogs .blog img {
  height: auto;
  max-width: 200px;
  border-radius: 8px;
}
.blogs .blog img.default {
  max-width: 150px;
  border-radius: 0;
}
.blogs .blog .date {
  font-size: 14px;
}
.blogs .blog h4 {
  text-align: center;
  font-family: "Bold";
  font-size: 16px;
}
@media (max-width: 600px) {
  .blogs .blog img {
    height: auto;
    max-width: 300px;
  }
  .blogs .blog .date {
    font-size: 16px;
  }
  .blogs .blog h4 {
    font-size: 17px;
  }
}

@media (max-width: 750px) {
  .blogs.preloader-blogs {
    flex-direction: column;
    align-items: center;
  }
  .blogs.preloader-blogs .preview-block {
    width: 80% !important;
  }
}

