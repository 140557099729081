.page-search {
  border-radius: 5px;
  margin: 0 auto;
  max-width: 350px;
  min-height: 40px;
  padding: 1px 4px;
  min-width: 360px;
  background-color: #f2f4f6;
  display: flex;
  gap: 5px;
  position: relative;
}
.page-search .elements-sorting {
  width: 30px;
  height: 30px;
  position: absolute;
  border-radius: 4px;
  left: -40px;
  top: 6px;
  background-color: #f2f4f6;
  transition: 0.3s all;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.page-search .elements-sorting img {
  height: auto;
  transition: 0.3s all;
  max-width: 18px;
}
.page-search .elements-sorting.active img {
  transform: rotate(180deg);
}
.page-search input {
  width: calc(100% - 35px);
  border: none;
  padding-left: 35px;
  background-image: url("../../../images/particals/search/search.svg");
  background-repeat: no-repeat;
  background-size: 19px;
  background-position: left;
  background-position-x: 6px;
  font-size: 15px;
  background-color: inherit;
}
.page-search input::placeholder {
  color: rgba(102, 102, 102, 0.8392156863);
}
.page-search .clear {
  align-self: center;
  min-width: 8px;
}
.page-search .clear span {
  display: none;
  cursor: pointer;
  user-select: none;
}
.page-search .clear span.active {
  display: block;
}
.page-search button {
  width: 70px;
  user-select: none;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 15px;
  font-family: "Bold";
  transition: 0.4s;
  color: rgba(68, 68, 68, 0.7019607843);
}
.page-search button:hover {
  color: #0082C7;
}

@media (max-width: 490px) {
  .page-search {
    min-width: calc(75% - 8px);
    max-width: calc(75% - 8px);
  }
  .page-search input::placeholder {
    font-size: 14px;
  }
  .page-search button {
    display: none;
  }
}

