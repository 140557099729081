@import '../../utils/variables.scss';

.blog-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    max-width: 800px;
    margin: 20px 0;
    font-size: 22px;
    text-align: center;
    font-family: 'Bold';
    color: $appColor;
  }

  .content {
    width: 100%;

    strong {
      font-family: 'Bold';
    }

    ul {
      li {
        list-style-type: circle;
      }
    }

    ol, ul {
      li {
        list-style-position: inside;
      }
    } 

    a {
      color: $appColor;
    }
  }
}