@import '../../../utils/variables.scss';
@import '../../../utils/mixins.scss';

.publication {
  margin-bottom: 20px;
  border-bottom: 0.5px solid #cccccc80;
  padding-bottom: 25px;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .publication-year {
    font-size: 13px;
    margin-left: 5px;
    background-color: #0082c712;
    font-family: 'Bold';
    color: #0082c7;
    padding: 2px 5px;
    border-radius: 3px;
  }

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;

  .journal {
    font-size: 14px;
    color: #636363;

    @include flex;
    justify-content: flex-start;
    gap: 7px;

    img {
      height: auto;
      max-width: 16px;
    }
  }

  .description{
    font-size: 17px;
    color: $appColor;
    text-align: left;

    &.non-active {
      color: $textColor;
      cursor: default;
    }

    .num {
      color: inherit;
      margin-right: 3px;
      // font-family: 'Bold';
    }

    .year {
      margin-left: 5px;
    }
  }

  .authors {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;

    .author { 
      border: 1px solid #ccc9;
      border-radius: 3px;
      padding: 2px 6px;
      font-size: 14px;
      letter-spacing: 0.2px;
    }
  }
}



@media (max-width: 800px) {
  .publication {
    .description{
      text-align: left;
    }
  }
}


@media (max-width: 580px) {
  .publication {
    .description{
      font-size: 17px;
    }
  
    .authors {  
      .author { 
        font-size: 14px;
      }
    }
  }
}