.page-title {
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  font-family: "Super-Bold";
  color: #0082C7;
  font-size: 32px;
}

@media (max-width: 730px) {
  .page-title {
    font-size: 24px;
  }
}

