@import '../../../utils/mixins.scss';

.blog {
  width: 400px;
  padding: 20px;

  box-shadow: -3px -3px 7px #e4e3e380, 3px 3px 7px #a8aeb478;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: #cccccc33;
  }

  @include flex-column;
  justify-content: flex-start;
  gap: 12px;

  img {
    height: auto;
    max-width: 200px;
    border-radius: 8px;

    &.default {
      max-width: 150px;
      border-radius: 0;
    }
  }

  .date {
    font-size: 14px;
  }

  h4 {
    text-align: center;
    font-family: 'Bold';
    font-size: 16px;
  }
}



@media (max-width: 600px) {
  .blog {   
    img {
      height: auto;
      max-width: 300px;
    }
  
    .date {
      font-size: 16px;
    }
  
    h4 {
      font-size: 17px;
    }
  }
}