.feedback-block {
  margin-top: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 18px;
}
.feedback-block img {
  height: auto;
  max-width: 100px;
}
.feedback-block .title {
  font-family: "Bold";
  font-size: 40px;
  color: rgba(65, 63, 66, 0.7215686275);
}
.feedback-block .message {
  max-width: 350px;
  text-align: center;
}
.feedback-block button {
  background-color: #0082C7;
  color: #fff;
  padding: 7px 10px;
  border: none;
  font-family: "Bold";
  font-size: 14px;
  border-radius: 5px;
  transition: 0.3s;
}
.feedback-block button:hover {
  opacity: 0.8;
}

@media (max-width: 450px) {
  .feedback-block {
    margin-top: 40px;
    gap: 10px;
  }
  .feedback-block img {
    max-width: 80px;
  }
}

