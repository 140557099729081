main .home-info {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
main .home-info .cover {
  border-radius: 10px;
  padding: 45px;
  background-color: #0082C7;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  position: relative;
  z-index: 1;
}
main .home-info .cover::before {
  content: "";
  z-index: -1;
  border-radius: 10px;
  background-color: #0082C7;
  background-image: url("/src/assets/images/pages/home/background.jpeg");
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 130, 199, 0.7411764706);
  filter: blur(1px);
}
main .home-info .cover .general-info {
  gap: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
main .home-info .cover .general-info .description {
  font-size: 14px;
}
main .home-info .cover .general-info .title {
  font-size: 67px;
  font-family: "Super-Bold";
  text-transform: uppercase;
}
@media (min-width: 1000px) {
  main .home-info .cover .general-info .title {
    font-size: 51px;
  }
}
main .home-info .cover .general-info .about {
  font-size: 17px;
}
main .home-info .cover .general-info .title, main .home-info .cover .general-info .description, main .home-info .cover .general-info .about {
  color: #fff;
  text-align: center;
}
main .home-info .cover .general-info .links {
  display: flex;
  gap: 20px;
}
main .home-info .cover .general-info .links a {
  min-width: 200px;
  min-height: 48px;
  border-radius: 8px;
  color: #fff;
  border: 2px solid #fff;
  font-family: "Bold";
  transition: 0.5s;
  font-size: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
main .home-info .cover .general-info .links a.active {
  background-color: #fff;
  color: #0082C7;
}
main .home-info .cover .general-info .links a:hover {
  opacity: 0.7;
}
main .home-info .cover .info-image img {
  height: auto;
  max-width: 600px;
  border-radius: 15px;
  border: 2px solid #fff;
}
main .home-question {
  text-align: center;
  margin: 40px auto;
  font-family: "Super-Bold";
  font-size: 32px;
  color: #0082C7;
}
main .home-question span {
  color: #0082C7;
  font-family: "Super-Bold";
  text-transform: capitalize;
}
main .home-blocks {
  gap: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
main .home-blocks .home-block {
  width: 47%;
  gap: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  justify-content: space-between;
}
main .home-blocks .home-block span {
  font-size: 14px;
  text-align: center;
}
main .home-blocks .home-block span a {
  font-family: Medium;
  color: #0082C7;
}
main .home-blocks .home-block img {
  height: auto;
  max-width: 100px;
}

@media (max-width: 1250px) {
  main .home-info {
    min-height: 0;
    margin: 40px 0;
    margin-top: 10px;
    padding-bottom: 40px;
    border-bottom: 1px solid rgba(204, 204, 204, 0.3215686275);
  }
  main .home-info .cover .general-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  main .home-info .cover .general-info .description {
    font-size: 17px;
  }
  main .home-info .cover .general-info .title, main .home-info .cover .general-info .description, main .home-info .cover .general-info .about {
    color: #fff;
  }
  main .home-info .cover .general-info .links {
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  main .home-info .cover .general-info .links a {
    min-width: 220px;
    min-height: 48px;
    font-size: 16px;
    border-color: #fff;
    color: #fff;
  }
  main .home-info .cover .general-info .links a.active {
    background-color: #fff;
    color: #0082C7;
  }
  main .home-info .cover .info-image img {
    display: none;
  }
  main .home-blocks .home-block {
    width: 80%;
  }
  main .home-blocks .home-block span {
    font-size: 19px;
    text-align: center;
  }
}
@media (max-width: 1000px) {
  main .home-info .cover .general-info .description {
    font-size: 16px;
  }
  main .home-info .cover .general-info .title {
    font-size: 40px;
  }
  main .home-info .cover .general-info .about {
    font-size: 19px;
  }
  main .home-blocks .home-block {
    width: 85%;
  }
  main .home-blocks .home-block span {
    font-size: 17px;
  }
}
@media (max-width: 700px) {
  main .home-info .cover .general-info .description {
    font-size: 12px;
  }
  main .home-info .cover .general-info .title {
    font-size: 32px;
  }
  main .home-info .cover .general-info .about {
    font-size: 14px;
  }
  main .home-info .cover .general-info .links a {
    min-width: 200px;
    min-height: 48px;
    font-size: 16px;
  }
  main .home-question {
    font-size: 24px;
  }
  main .home-blocks .home-block {
    width: 85%;
  }
  main .home-blocks .home-block span {
    font-size: 14px;
  }
  main .home-blocks .home-block img {
    height: auto;
    max-width: 70px;
  }
}
@media (max-width: 500px) {
  main .home-info .cover {
    padding: 20px;
  }
  main .home-info .cover .general-info .description {
    font-size: 12px;
  }
  main .home-info .cover .general-info .title {
    font-size: 28px;
  }
  main .home-info .cover .general-info .links {
    width: 100%;
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  main .home-info .cover .general-info .links a {
    min-height: 42px;
    min-width: auto;
    width: 48%;
    font-size: 13px;
  }
  main .home-question {
    font-size: 20px;
  }
  main .home-blocks .home-block {
    width: 90%;
  }
  main .home-blocks .home-block span {
    font-size: 13px;
  }
  main .home-blocks .home-block img {
    max-width: 60px;
  }
}

