@import '../utils/variables.scss';
@import '../utils/mixins.scss';

.contact-us-page {
  @include flex-column;
  gap: 25px;

  .preview {
    font-size: 17px;
    max-width: 500px;
    text-align: center;

    display: block;
  }

  .contact-us-form {
    min-width: 420px;
    border-radius: 4px;
    opacity: hidden;

    display: flex;
    flex-direction: column;
    gap: 6px;

    textarea {
      min-height: 150px;
      resize: none;
    }

    button, textarea, input {
      border: none;
      background: none;
      font-size: 16px;
      background-color: #f2f4f6;
      border-radius: 5px;
    }

    input {
      padding: 10px;
      font-family: 'Bold';
    }

    textarea {
      padding: 0 10px;
      padding-top: 10px;
    }

    button {
      padding: 10px 0;
      font-family: 'Bold';
      color: #413f42a3;
      cursor: not-allowed;
      transition: 0.4s;

      &.active {
        color: $appColor;
        transition: 0.4s;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}



@media (max-width: 500px) {

  .contact-us-page {
    .contact-us-form {
      min-width: auto;
      width: 100%;
    }
  }
}