.contact-us-page {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
}
.contact-us-page .preview {
  font-size: 17px;
  max-width: 500px;
  text-align: center;
  display: block;
}
.contact-us-page .contact-us-form {
  min-width: 420px;
  border-radius: 4px;
  opacity: hidden;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.contact-us-page .contact-us-form textarea {
  min-height: 150px;
  resize: none;
}
.contact-us-page .contact-us-form button, .contact-us-page .contact-us-form textarea, .contact-us-page .contact-us-form input {
  border: none;
  background: none;
  font-size: 16px;
  background-color: #f2f4f6;
  border-radius: 5px;
}
.contact-us-page .contact-us-form input {
  padding: 10px;
  font-family: "Bold";
}
.contact-us-page .contact-us-form textarea {
  padding: 0 10px;
  padding-top: 10px;
}
.contact-us-page .contact-us-form button {
  padding: 10px 0;
  font-family: "Bold";
  color: rgba(65, 63, 66, 0.6392156863);
  cursor: not-allowed;
  transition: 0.4s;
}
.contact-us-page .contact-us-form button.active {
  color: #0082C7;
  transition: 0.4s;
  cursor: pointer;
}
.contact-us-page .contact-us-form button.active:hover {
  opacity: 0.8;
}

@media (max-width: 500px) {
  .contact-us-page .contact-us-form {
    min-width: auto;
    width: 100%;
  }
}

