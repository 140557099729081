.preview-block {
  min-height: 30px;
  width: 100%;
  border-radius: 4px;

  background: linear-gradient(-45deg, #ccc, #fff, #EEEEEE, #ccc);
	background-size: 400% 400%;
	animation: gradient 5s ease infinite;
  box-shadow: -3px -3px 7px #f2f2f2, 3px 3px 7px #c6cace;
  margin: 10px 0;

	&:first-child {
		margin-top: 0;
	}
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
