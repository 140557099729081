@import '../utils/variables.scss';
@import '../utils/mixins.scss';

main {
  .error_message {
    text-transform: uppercase;
    margin-top: 120px;

    @include flex-column;

    span {
      font-family: 'Super-Bold';
      color: $appColor;
      font-size: 96px;
    }

    p {
      font-size: 43px;
    }
  }

  .link-block {
    @include flex;

    a {
      border-radius: 30px 0 30px 0;
      margin-top: 20px;
      padding: 10px 40px;
      font-family: 'Bold';
      background-color: $appColor;
      font-size: 19px;
      color: #fff;
      transition: 0.4s;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}



@media (max-width: 500px) {
  main {
    .error_message {
      text-align: center;
      margin-top: 70px;
  
      span {
        font-size: 64px;
      }
  
      p {
        font-size: 24px;
      }
    }
  
    .link-block {
      a {
        font-size: 12px;
        }
    }
  }
}