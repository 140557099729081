.main-footer {
  margin-top: 50px;
  padding: 30px 100px;
  background: linear-gradient(to bottom right, rgb(2, 79, 126) 40%, rgba(10, 100, 130, 0.8588235294));
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.main-footer .cover {
  width: 100%;
  max-width: 1350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.main-footer .cover .info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
}
.main-footer .cover .info h4 {
  color: rgba(255, 255, 255, 0.9098039216);
  font-size: 27px;
  text-transform: uppercase;
}
.main-footer .cover .info ul {
  display: flex;
  gap: 40px;
}
.main-footer .cover .info ul li {
  list-style-type: none;
}
.main-footer .cover .info ul li span {
  font-family: Medium;
  color: #fff;
}
.main-footer .cover .info ul li a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-right: 5px;
  border-radius: 3px;
}
.main-footer .cover .info ul li a img {
  height: auto;
  max-width: 40px;
}
.main-footer .cover .info ul li a span {
  font-size: 17px;
  transition: 0.4s;
}
.main-footer .cover .info ul li a span:hover {
  opacity: 0.6;
}
.main-footer .map {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.main-footer hr {
  border: none;
  margin: 20px 0;
  height: 1px;
  width: 100%;
  max-width: 1350px;
  background-color: rgba(255, 255, 255, 0.337254902);
}
.main-footer .description {
  text-align: center;
  font-size: 17px;
  color: rgba(255, 255, 255, 0.9098039216);
}
.main-footer .description .line {
  color: #fff;
}

@media (max-width: 1115px) {
  .main-footer .cover {
    justify-content: center;
  }
  .main-footer .cover .info h4 {
    font-size: 24px;
    text-align: center;
  }
  .main-footer .cover .info ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  .main-footer .cover .info ul li a {
    gap: 7px;
  }
  .main-footer .cover .info ul li a img {
    max-width: 30px;
  }
  .main-footer .cover .info ul li a span {
    font-size: 17px;
  }
  .main-footer .description {
    font-size: 14px;
  }
  .description {
    text-align: center;
  }
}
@media (max-width: 640px) {
  .main-footer {
    padding: 20px 40px;
  }
  .main-footer .cover .info h4 {
    font-size: 20px;
  }
  .main-footer .cover .info ul li a {
    text-align: center;
  }
  .main-footer .cover .info ul li a img {
    display: none;
  }
  .main-footer .cover .info ul li a span {
    font-size: 16px;
  }
  .main-footer .description {
    font-size: 12px;
  }
}

