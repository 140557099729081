@import '../utils/mixins.scss';

.no-result-message {
  @include flex;

  span {
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 14px;
    user-select: none;
    border: 1px solid #413f4261;
    color: #413f4294;
  }
  
  margin: 0 auto;
}