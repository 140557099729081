@font-face {
  font-family: 'Light';
  src: url('../../fonts/Montserrat/Montserrat-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Regular';
  src: url('../../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Medium';
  src: url('../../fonts/Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bold';
  src: url('../../fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Super-Bold';
  src: url('../../fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}