@import '../utils/variables.scss';
@import '../utils/mixins.scss';

.main-footer {
  margin-top: 50px;
  padding: 30px 100px;
  background: linear-gradient(to bottom right, rgb(2 79 126) 40%, #0a6482db);

  @include flex-column;

  .cover {
    width: 100%;
    max-width: $maxWidth;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .info {
      width: 100%;
      @include flex-column;
      gap: 15px;
  
      h4 {
        color: #ffffffe8;
        font-size: 27px;
        text-transform: uppercase;
      }
  
      ul {
        display: flex;
        gap: 40px;

        li {
          list-style-type: none;

          span {
            font-family: Medium;
            color: #fff;
          }

          a {
            @include flex;
            gap: 10px;
            padding-right: 5px;
            border-radius: 3px;

            img {
              height: auto;
              max-width: 40px;
            }
  
            span {
              font-size: 17px;
              transition: 0.4s;

              &:hover {
                opacity: 0.6;
              }
            }
          }
        }
      }
    }
  }

  .map {
    margin-top: 20px;
    width: 100%;

    display: flex;
    justify-content: center;
  }

  hr {
    border: none;
    margin: 20px 0;
    height: 1px;
    width: 100%;
    max-width: $maxWidth;
    background-color: #ffffff56;
  }

  .description {
    text-align: center;
    font-size: 17px;
    color: #ffffffe8;

    .line {
      color: #fff;
    }
  }
}


@media (max-width: 1115px) {
  .main-footer {
    .cover {
      justify-content: center;

      .info {
        h4 {
          font-size: 24px;
          text-align: center;
        }

        ul {
          @include flex-column;
          align-items: center;
          gap: 15px;

          li {
            a {
              gap: 7px;

              img {
                max-width: 30px;
              }

              span {
                font-size: 17px;
              }
            }
          }
        }
      }
    }

    .description {
      font-size: 14px;
    }
  }

  .description {
    text-align: center;
  }
}

@media (max-width: 640px) {
  .main-footer {
    padding: 20px 40px;
    
    .cover {
      .info {
        h4 {
          font-size: 20px;
        }

        ul {
          li {
            a {
              text-align: center;

              img {
                display: none;
              }

              span {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .description {
      font-size: 12px;
    }
  }
}