.projects {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
}
.projects.preloader-projects .preview-block {
  margin: 0;
}
.projects .project {
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  transition: 0.3s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: flex-start;
  gap: 14px;
}
.projects .project .title {
  color: rgba(65, 63, 66, 0.8588235294);
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
  transition: 0.3s;
}
.projects .project .title:hover {
  opacity: 0.8;
}
.projects .project .title img {
  height: auto;
  max-width: 20px;
}
.projects .project img {
  height: auto;
  max-width: 600px;
  border-radius: 8px;
}
.projects .project img.default {
  max-width: 150px;
  border-radius: 0;
}
.projects .project .devs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 600px;
}
.projects .project .devs .dev {
  border: 1px solid rgba(204, 204, 204, 0.6);
  border-radius: 3px;
  padding: 2px 6px;
  font-size: 15px;
  letter-spacing: 0.2px;
}
.projects .project .date, .projects .project .content {
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  max-width: 800px;
}
.projects .project .date a, .projects .project .content a {
  color: #337ab7;
}
.projects .project .date b, .projects .project .date strong, .projects .project .content b, .projects .project .content strong {
  font-family: "Bold";
}
.projects .project h4 {
  text-align: center;
  font-family: "Bold";
  font-size: 16px;
}
@media (max-width: 600px) {
  .projects .project img {
    height: auto;
    max-width: 360px;
  }
  .projects .project .date {
    font-size: 16px;
  }
  .projects .project h4 {
    font-size: 17px;
  }
}

@media (max-width: 750px) {
  .projects.preloader-projects {
    flex-direction: column;
    align-items: center;
  }
  .projects.preloader-projects .preview-block {
    width: 80% !important;
  }
}

