@import '../utils/variables.scss';
@import '../utils/mixins.scss';

main {
  .home-info {
    // min-height: calc(100vh - 75px);
    margin-top: 20px;

    @include flex;

    .cover {
      border-radius: 10px;
      padding: 45px;
      background-color: $appColor;
      
      @include flex-column;
      gap: 20px;

      position: relative;
      z-index: 1;

      &::before {
        content: '';
        z-index: -1;
        border-radius: 10px;
        background-color: $appColor;
        background-image: url('/src/assets/images/pages/home/background.jpeg');
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #0082c7bd;
        filter: blur(1px);
      }

      .general-info {
        gap: 15px;
        @include flex-column;
  
        .description {
          font-size: 14px;
        }
  
        .title {
          // color: $appColor;
          font-size: 67px;
          font-family: 'Super-Bold';
          text-transform: uppercase;

          @media (min-width: 1000px) {
            font-size: 51px;
          }
        }
  
        .about {
          font-size: 17px;
        }
        
        .title, .description, .about {
          color: #fff;
          text-align: center;
        }

  
        .links {
          display: flex;
          gap: 20px;
  
          a {
            min-width: 200px;
            min-height: 48px;
            border-radius: 8px;
            color: #fff;
            border: 2px solid #fff;
            font-family: 'Bold';
            transition: 0.5s;
            font-size: 17px;

            @include flex;

            &.active {
              background-color: #fff;
              color: $appColor;
            }
  
            &:hover {
              opacity: 0.7;
            }

            // &:first-child {
            //   background-color: #fff;
            //   color: $appColor;
            // }
          }
        }
      }
  
      .info-image {
        img {
          height: auto;
          max-width: 600px;
          border-radius: 15px;
          // box-shadow: 6px 6px 7px #e4e3e3;
          border: 2px solid #fff;
        }
      }
    }
  }

  .home-question {
    text-align: center;
    margin: 40px auto;
    font-family: 'Super-Bold';
    font-size: 32px;
    color: $appColor;

    span {
      color: $appColor;
      font-family: 'Super-Bold';
      text-transform: capitalize;
    }
  }

  .home-blocks {
    gap: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .home-block {
      width: 47%;

      gap: 15px;
      @include flex;
      flex-direction: column-reverse;
      justify-content: space-between;

      span {
        font-size: 14px;
        text-align: center;

        a {
          font-family: Medium;
          color: $appColor;
        }
      }

      img {
        height: auto;
        max-width: 100px;
      }
    }
  }
}




@media (max-width: 1250px) {
  main {
    .home-info {  
      min-height: 0;
      margin: 40px 0;
      margin-top:10px;
      padding-bottom: 40px;
      border-bottom: 1px solid #cccccc52;

      .cover {    
        .general-info {
          @include flex-column;
          text-align: center;

          .description {
            font-size: 17px;
          }

          .title, .description, .about {
            color: #fff;
          }

          .links {
            width: 100%;
            margin-top: 15px;

            @include flex;

            a {
              min-width: 220px;
              min-height: 48px;
              font-size: 16px;
              border-color: #fff;
              color: #fff;

              &.active {
                background-color: #fff;
                color: $appColor;
              }
            }
          }
        }
    
        .info-image {
          img {
           display: none;
          }
        }
      }
    }

    .home-blocks {
      .home-block {
        width: 80%;
  
        span {
          font-size: 19px;
          text-align: center;
        }
      }
    }
  }
}



@media (max-width: 1000px) {
  main {
    .home-info {  
      .cover {    
        .general-info {
          .description {
            font-size: 16px;
          }

          .title {
            font-size: 40px;
          }

          .about {
            font-size: 19px;
          }
        }
      }
    }


    .home-blocks {
      .home-block {
        width: 85%;
  
        span {
          font-size: 17px;
        }
      }
    }
  }
}

@media (max-width: 700px) {
  main {
    .home-info {  
      .cover {    
        .general-info {
          .description {
            font-size: 12px;
          }

          .title {
            font-size: 32px;
          }

          .about {
            font-size: 14px;
          }

          .links {
            a {
              min-width: 200px;
              min-height: 48px;
              font-size: 16px;
            }
          }
        }
      }
    }


    .home-question{
      font-size: 24px;
    }

    .home-blocks {
      .home-block {
        width: 85%;
  
        span {
          font-size: 14px;
        }
  
        img {
          height: auto;
          max-width: 70px;
        }
      }
    }
  }
}


@media (max-width: 500px) {
  main {
    .home-info {  
      .cover {  
        padding: 20px;

        .general-info {
          .description {
            font-size: 12px;
          }

          .title {
            font-size: 28px;
          }

          .links {
            width: 100%;
            margin-top: 15px;

            @include flex;
            // flex-wrap: wrap;

            a {
              min-height: 42px;
              min-width: auto;
              width: 48%;
              font-size: 13px;
            }

          }
        }
      }
    }


    .home-question {
      font-size: 20px;
    }

    .home-blocks {
      .home-block {
        width: 90%;
  
        span {
          font-size: 13px;
        }
  
        img {
          max-width: 60px;
        }
      }
    }
  }
}