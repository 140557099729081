.blogs {
  margin-top: 20px;

  display: flex;
  justify-content: center;

  flex-wrap: wrap;
  gap: 50px;

  &.preloader-blogs {
    .preview-block {
      margin: 0;
    }
  }

  @import './components/blog-item.scss';
}

@media (max-width: 750px) {
  .blogs {
    &.preloader-blogs {
      flex-direction: column;
      align-items: center;

      .preview-block {
        width: 80% !important;
      }
    }
  }
}