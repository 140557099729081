.members-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin: 40px 0;
  padding-bottom: 11px;
  border-bottom: 1px solid #d2d2d7;
}
.members-toggle span {
  color: #6e6e73;
  cursor: pointer;
  font-size: 17px;
  user-select: none;
}
.members-toggle span.active {
  color: #000;
  position: relative;
}
.members-toggle span.active::before {
  content: "";
  height: 1px;
  width: 100%;
  background-color: #000;
  position: absolute;
  bottom: -12px;
}

.team {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.team.preloader-mode {
  gap: 0;
}
.team .member {
  display: flex;
  gap: 15px;
}
.team .member .profile-image img {
  height: auto;
  max-width: 130px;
  border-radius: 10px;
  box-shadow: -3px -3px 7px #f2f2f2, 3px 3px 7px #c0c9d2;
}
.team .member .profile-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.team .member .profile-info .name {
  font-size: 27px;
  font-family: "Bold";
}
.team .member .profile-info .job-title {
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
}
.team .member .profile-info .job-title span {
  border: 1px solid #0082C7;
  color: #0082C7;
  border-radius: 2px;
  font-size: 14px;
  padding: 2px 6px;
}
.team .member .profile-info .description {
  font-size: 14px;
}
.team .member .profile-info .description .toggle-info {
  background: none;
  border: none;
  cursor: pointer;
  color: #0082C7;
  font-family: "Bold";
  margin-left: 4px;
}

@media (max-width: 1000px) {
  .team .member .profile-info .name {
    font-size: 22px;
  }
  .team .member .profile-info .job-title {
    gap: 5px;
  }
}
@media (max-width: 600px) {
  .team .member {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .team .member .profile-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .team .member .profile-info .name {
    text-align: center;
  }
  .team .member .profile-info .job-title {
    justify-content: center;
  }
  .team .member .profile-info .job-title span {
    font-size: 12px;
    text-align: center;
  }
  .team .member .profile-info .description {
    text-align: center;
  }
}

