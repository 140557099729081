@import './assets/styles/scss/utils/fonts.scss';

* {
  margin: 0;
  padding: 0;
  font-family: 'Regular', Arial;
  color: #060506c4;
}

html,
body, 
#root {
  height: 100%;
}

.application {
  height: 100%;

  display: flex;
  flex-direction: column;
}

.container {
  max-width: 1350px !important;
  width: calc(100% - 80px);
  min-height: 100vh;
  margin: 0 auto;
  margin-top: 61px; /* Header height */
  padding: 0 40px;
  padding-top: 20px;
  align-items: center;

  flex: 1 0 auto;
}

.main-footer, .main-header {
  flex-shrink: 0;
}

a {
  text-decoration: none;
  transition: 0.5s all;
  user-select: none;
}

input, button, textarea {
  outline: none;
  font-family: 'Regular', Arial;
}

button {
  user-select: none;
  cursor: pointer;
}

ul li {
  list-style-type: none;
}

@media (max-width: 490px) {
  .container {
    width: calc(100% - 40px);
    padding: 0 20px;
    padding-top: 15px;
  }
}