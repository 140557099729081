.publication {
  margin-bottom: 20px;
  border-bottom: 0.5px solid rgba(204, 204, 204, 0.5019607843);
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}
.publication:last-child {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}
.publication .publication-year {
  font-size: 13px;
  margin-left: 5px;
  background-color: rgba(0, 130, 199, 0.0705882353);
  font-family: "Bold";
  color: #0082c7;
  padding: 2px 5px;
  border-radius: 3px;
}
.publication .journal {
  font-size: 14px;
  color: #636363;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  gap: 7px;
}
.publication .journal img {
  height: auto;
  max-width: 16px;
}
.publication .description {
  font-size: 17px;
  color: #0082C7;
  text-align: left;
}
.publication .description.non-active {
  color: rgba(6, 5, 6, 0.768627451);
  cursor: default;
}
.publication .description .num {
  color: inherit;
  margin-right: 3px;
}
.publication .description .year {
  margin-left: 5px;
}
.publication .authors {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
}
.publication .authors .author {
  border: 1px solid rgba(204, 204, 204, 0.6);
  border-radius: 3px;
  padding: 2px 6px;
  font-size: 14px;
  letter-spacing: 0.2px;
}

@media (max-width: 800px) {
  .publication .description {
    text-align: left;
  }
}
@media (max-width: 580px) {
  .publication .description {
    font-size: 17px;
  }
  .publication .authors .author {
    font-size: 14px;
  }
}

