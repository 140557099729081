@import '../../../utils/mixins.scss';
@import '../../../utils/variables.scss';

.project {
  padding: 20px;
  width: 100%;

  border-radius: 10px;
  transition: 0.3s;

  // &:hover {
  //   background-color: #cccccc33;
  // }

  @include flex-column;
  justify-content: flex-start;
  gap: 14px;

  .title {
    color: $grayColor;
    font-size: 18px;
    @include flex;
    gap: 5px;
    cursor: pointer;
    
    transition: 0.3s;

    &:hover {
      opacity: 0.8;
    }
    
    img {
      height: auto;
      max-width: 20px;
    }
  }

  img {
    height: auto;
    max-width: 600px;
    border-radius: 8px;

    &.default {
      max-width: 150px;
      border-radius: 0;
    }
  }

  .devs {
    @include flex;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 600px;

    .dev {
      border: 1px solid rgba(204, 204, 204, 0.6);
      border-radius: 3px;
      padding: 2px 6px;
      font-size: 15px;
      letter-spacing: 0.2px;
    }
  }

  .date, .content {
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    max-width: 800px;

    a {
      color: $linkColor;
    }

    b, strong {
      font-family: 'Bold';
    }
  }

  h4 {
    text-align: center;
    font-family: 'Bold';
    font-size: 16px;
  }
}



@media (max-width: 600px) {
  .project {   
    img {
      height: auto;
      max-width: 360px;
    }
  
    .date {
      font-size: 16px;
    }
  
    h4 {
      font-size: 17px;
    }
  }
}