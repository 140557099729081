.projects {
  margin-top: 20px;

  display: flex;
  justify-content: center;

  flex-wrap: wrap;
  gap: 50px;

  &.preloader-projects {
    .preview-block {
      margin: 0;
    }
  }

  @import './components/project-item.scss';
}

@media (max-width: 750px) {
  .projects {
    &.preloader-projects {
      flex-direction: column;
      align-items: center;

      .preview-block {
        width: 80% !important;
      }
    }
  }
}