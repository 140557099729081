@import '../utils/variables.scss';
@import '../utils/mixins.scss';

.main-header {
  width: calc(100% - 80px);
  background-color: #ffffffe8;
  border-bottom: 1px solid $opacityLineColor;
  padding: 15px $appPadding;

  display: flex;
  justify-content: center;
  align-items: center;
  
  position: fixed;
  z-index: 2;

  .cover {
    width: 100%;
    max-width: $maxWidth;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $maxWidth) {
      min-width: auto;
    }

    .logo {
      img {
        height: auto;
        max-width: 105px;
      }
    }
  
    ul {
      display: flex;
      gap: 15px;
  
      li {
        list-style-type: none;
  
        a {
          color: $textColor;
          user-select: none;
          text-transform: capitalize;
          font-family: 'Medium';
          color: #444;
  
          &:hover {
            color: $appColor;
          }
        }
      }
    }
  
    .special-link {
      background-color: $appColor;
      padding: 6px 10px;
      border-radius: 4px;
      font-family: 'Bold';
      color: #fff;
      text-transform: uppercase;
      font-size: 14px;
  
      &:hover {
        box-shadow: $blockShahow;
      }
    }

    .menu-button {
      display: none;
    }
  }

  .burger-menu {
    display: none;
  }
}

@media (max-width: 800px) {
  .main-header {
    padding: 8px 40px;
    flex-direction: column;

    .cover {
      ul { 
        display: none;
      }

      .special-link {
        display: none;
      }

      .menu-button {
        height: 40px;
        width: 40px;
        background: none;
        border: none; 

        @include flex-column;

        img {
          height: auto;
          max-width: 40px;
        }
      }
    }

    .burger-menu {
      display: flex;
      margin: 10px 0;

      ul {
        @include flex-column;
        gap: 15px;
    
        li {
          list-style-type: none;
    
          a {
            color: $textColor;
            user-select: none;
            text-transform: capitalize;
            font-family: 'Bold';
            font-size: 19px;
    
            &:hover {
              color: $appColor;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 490px) {
  .main-header {
    width: calc(100% - 40px);
    padding: 10px 20px;
  }
}