@import '../utils/mixins.scss';
@import '../utils/variables.scss';

.information {
  margin-bottom: 50px;
  @include flex-column;

  &:last-child {
    margin-bottom: 0;
  }

  .title-medium {
    font-family: 'Super-Bold';
    font-size: 22px;
    max-width: 900px;

    padding: 5px 15px;
    border-radius: 5px;
  }

  .description {
    margin-top: 10px;
    font-size: 14px;
    max-width: 600px;

    &.special {
      max-width: 900px;
    }

    b {
      font-family: 'Bold';
    }

    a {
      color: $appColor;
    }
  }

  .title-medium, .description {
    text-align: center;
  }

  .projects {
    margin-top: 30px;
    
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 35px;

    .project {
      max-width: 300px;

      @include flex-column;
      justify-content: flex-start;
      gap: 10px;

      img {
        height: auto;
        max-width: 60px;
      }

      span {
        font-size: 15px;
        font-family: 'Bold';
        max-width: 350px;
        text-align: center;
      }
    }
  }

  .areas {
    margin-top: 15px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;

    .area {
      border-radius: 4px;
      border: 1px solid #ccc;
      text-transform: capitalize;
      font-size: 12px;
      font-family: 'Bold';
      padding: 2px 8px;
    }
  }
}

.preloaders {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.information-blocks {
  @include flex-column;
  gap: 10px;

  .information-block {
    @include flex-column;
    
    .title-medium {
      font-family: 'Super-Bold';
      font-size: 19px;
      max-width: 1000px;
  
      // background-color: #cccccc40;
      padding: 5px 15px;
      border-radius: 5px;

      display: flex;
      align-items: flex-start;
      position: relative;

      .toggle-info {
        height: 19px;
        width: 19px;
        background: #0082c7ad;
        border-radius: 50%;
        border: none;
        font-family: 'Bold';
        flex-direction: column;
        color: #fff;
        font-size: 15px;
        cursor: pointer;
        transition: 0.4s;

        position: absolute;
        right: -10px;
        top: 50%;
        transform: translate(0, -50%);

        @include flex-column;

        &:hover {
          background: $appColor;
        }
      }
    }

    .description {
      margin-top: 5px;
      font-size: 14px;
      max-width: 600px;
  
      &.special {
        max-width: 900px;
      }
  
      b {
        font-family: 'Bold';
      }
  
      a {
        color: $appColor;
      }
    }
  
    .title-medium, .description {
      text-align: center;
    }
  }
}



@media (max-width: 1170px) {
  .information {  
    .projects {
      justify-content: center;
    }
  }
}



@media (max-width: 730px) {
  .information {  
    .title-medium {
      font-size: 19px;
    }

    .projects {
      .project {  
        img {
          max-width: 50px;
        }
  
        span {
          font-size: 12px;
        }
      }
    }

    .description {
      font-size: 13px;
    }

    .areas {
      .area {
        text-align: center;
        font-size: 12px;
      }
    }
  }

  .information-blocks {
    .information-block {
      .title-medium {
        font-size: 16px;
      }

      .description { 
        font-size: 12px;
      }
    }
  }
}