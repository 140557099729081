$appColor: #0082C7;
$grayColor: #413f42db;
$opacityLineColor: #cccccc59;
$textColor: #060506c4;
$backgroupAppColor: #F7F7F7;
$linkColor: #337ab7;

$blockShahow: -3px -3px 7px #e4e3e3, 3px 3px 7px #a8aeb4;

$appPadding: 40px;
$maxWidth: 1350px;